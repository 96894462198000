
import {Component, Prop, Watch} from 'vue-property-decorator';
import AbpBase from '../../../../lib/abpbase'
import Company from "@/store/entities/companycontext/company";

@Component
export default class ContactForm extends AbpBase {
  @Watch('company')
  onCompany(val, oldVal) {
    if (val.logo) {
      this.imagePresents = true;
    }
  }

  @Prop({type: Boolean, default: false}) toEdit: boolean;
  @Prop({default: new Company()}) company: Company
  categories: any;
  private imagePresents: boolean = false;

  handleUpload(file) {
    this.company.uploadedLogo = file;
    this.company.logo = URL.createObjectURL(file);
    this.imagePresents = true;
  }

  data() {
    return {
      categories: []
    }
  };

  async mounted() {
    this.categories = await this.$store.dispatch('category/lookUp');
  }

  getCompany() {
    let formData = new FormData();
    if (this.company.name) {
      formData.append('name', this.company.name);
    }
    if (this.company.id) {
      formData.append('id', this.company.id.toString());
    }
    if (this.company.industry) {
      formData.append('industry', this.company.industry);
    }
    if (this.company.registrationNumber) {
      formData.append('registrationNumber', this.company.registrationNumber);
    }
    if (this.company.isTaxPayer !== undefined) {
      formData.append('isTaxPayer', this.company.isTaxPayer.toString());
    }
    if (this.company.webSite) {
      formData.append('webSite', this.company.webSite);
    }
    if (this.company.email) {
      formData.append('email', this.company.email);
    }
    if (this.company.phoneNumber) {
      formData.append('phoneNumber', this.company.phoneNumber);
    }
    if (this.company.note) {
      formData.append('note', this.company.note);
    }
    if (this.company.abn) {
      formData.append('abn', this.company.abn);
    }
    if (this.company.isEnabled !== undefined) {
      formData.append('isEnabled', this.company.isEnabled.toString());
    }
    if (this.company.categories) {
      this.company.categories.forEach((value, index) => {
        formData.append('categories[' + index + ']', value.toString())
      });
    }
    if (this.company.uploadedLogo) {
      formData.append('logo', this.company.uploadedLogo);
    } else {
      if (this.company.logo) {
        formData.append('logo', this.b64toBlob(this.company.logo));
      }
    }
    return formData;
  }

  b64toBlob(dataURI) {
    let byteString = atob(dataURI.split(',')[1]);
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], {type: dataURI.match(/[^:]\w+\/[\w-+\d.]+(?=[;,])/)[0]});
  }
}
